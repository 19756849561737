import { useQuery } from 'react-query';
import { ProjectRepository } from '../../repository';
import { Auth } from '@gamebase-web/common';

export default function useProjectSheetLanguage(projectId?: string) {
    const { data } = useQuery([projectId, 'sheetLanguages'], () => {
        return projectId ? ProjectRepository.getProjectSheetLanguageList(new Auth({ projectId })) : { result: [] };
    });

    return {
        list: data?.result ?? [],
    };
}

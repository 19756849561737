import { Form, Input, Button, Select, Radio, Descriptions, Tag, Tooltip, Modal } from 'antd';
import { ModalWrapper } from 'components';
import { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import AccountDropDown from '../role/AccountDropDown';
import classNames from 'classnames/bind';
import styles from './ProjectModal.module.scss';
import EnterIgnoreForm from 'components/utils/EnterIgnoreForm';
import { ProjectCreateRequest, ProjectDetailDto, ProjectUpdateRequest } from '@gamebase-web-ops/project';
import useProjectDetail from 'hooks/project/useProjectDetail';
import { useFormRules, useAccounts } from 'hooks';
import useGameManage from 'hooks/game/useGameManage';
import { EProjectEnvironment } from '@gamebase-web-ops/project';
import ProjectMenuEdit from './ProjectMenuEdit';
import { AccountBasicDto } from '@gamebase-web-ops/account';
import { notificationPush } from 'utils/notificationUtils';
import { css } from '@emotion/react';
import { BUTTON_TYPE } from './ProjectList';
import GameSheetModal from './game-sheet-modal';
import LanguageCodes from '@percent/language-code';
import useProjectSheetLanguage from '../../hooks/project/useProjectSheetLanguage';
import { InfoCircleOutlined } from '@ant-design/icons';

const cx = classNames.bind(styles);

interface IProp extends ModalWrapperProps {
    onSubmit?: (arg: ProjectCreateRequest | ProjectUpdateRequest) => void | Promise<void>;
    isProjectMenuList: boolean;
    projectValues: ProjectDetailDto | null;
    mode: BUTTON_TYPE;
}

const { Search } = Input;

const ProjectModal: React.FC<IProp> = ({
    visible,
    onCancel,
    onSubmit,
    title,
    projectValues,
    isProjectMenuList,
    mode,
}) => {
    const isProd = process.env.REACT_APP_ENV === 'production';
    const {
        accounts: searchResultAccounts,
        requestAccountsByKeyword,
        setSearchAccounts,
        searchAccounts: projectAccounts,
        addAccounts,
        removeAccounts,
    } = useAccounts();

    const searchRef = useRef<any>();
    const [dropDownVisible, setDropDownVisible] = useState(false);
    const { projectDetail, refetch } = useProjectDetail(projectValues?.id);
    const { allGameList } = useGameManage();
    const { formValidator } = useFormRules();
    const [hasSheetId, setHasSheetId] = useState<boolean | undefined>(
        projectDetail ? (projectDetail?.driveUrl ? true : false) : undefined,
    );
    const { list: sheetLanguages } = useProjectSheetLanguage(projectValues?.id);

    const [sheetInfo, setSheetInfo] = useState<{ driveUrl: undefined | string; serverUrl: undefined | string }>({
        driveUrl: projectDetail?.driveUrl,
        serverUrl: projectDetail?.serverUrl,
    });

    const [gameId, setGameId] = useState<number>(-1);
    const [languages, setLanguages] = useState<string[]>([]);

    useEffect(() => {
        if (projectDetail?.managers) {
            setSearchAccounts(projectDetail?.managers);
        }
        setHasSheetId(projectDetail ? !!projectDetail?.driveUrl : undefined);
        setSheetInfo({
            driveUrl: projectDetail?.driveUrl,
            serverUrl: projectDetail?.serverUrl,
        });
        setGameId(projectDetail?.globalId ?? -1);
        if (projectDetail?.languages?.length) {
            setLanguages(projectDetail?.languages);
        }
    }, [projectDetail, setSearchAccounts]);

    const onSearch = useCallback(
        (keyword: string, event) => {
            event.stopPropagation();
            event.preventDefault();

            setDropDownVisible(true);
            requestAccountsByKeyword(keyword);
        },
        [requestAccountsByKeyword],
    );
    const onAccountItemClick = useCallback(
        (param: AccountBasicDto) => {
            setDropDownVisible(false);
            addAccounts(param);
        },
        [addAccounts],
    );

    const onFinish = useCallback(
        async (arg) => {
            if (window.confirm('정말로 제출 하시겠습니까?')) {
                if (onSubmit) {
                    if (projectAccounts.length === 0 && projectValues) {
                        return notificationPush({ status: 'error', message: '담당자를 한 명 이상 추가해주세요.' });
                    }

                    const globalId = parseInt(arg.globalId, 10);
                    const name = `${allGameList?.find((gameInfo) => gameInfo.gameId === globalId)?.gameName}(${
                        arg.environment
                    })`;
                    const submitData = {
                        ...arg,
                        serverUrl: (sheetInfo.serverUrl ?? '').replace(/ /g, ''),
                        driveUrl: hasSheetId ? (sheetInfo.driveUrl ?? '').replace(/ /g, '') : '',
                        name,
                        globalId,
                        id: projectValues?.id,
                        managerIds: projectAccounts.map(({ id }) => id),
                        languages,
                    };
                    await onSubmit(submitData);
                    refetch();
                }
            }
        },
        [onSubmit, projectAccounts, projectValues, allGameList, sheetInfo, refetch, hasSheetId, languages],
    );
    const [form] = Form.useForm();

    const [gameSheetModalVisible, setGameSheetModalVisible] = useState<boolean>(false);

    if (projectValues && !projectDetail) return null;

    return (
        <ModalWrapper title={title} onCancel={onCancel} visible={visible} width={'750px'}>
            <section className={projectDetail && cx('wrapper')}>
                <EnterIgnoreForm
                    form={form}
                    enabledenter
                    className={projectDetail && cx('formWrapper')}
                    onFinish={onFinish}
                    labelCol={{ span: 3 }}
                >
                    <Descriptions column={1} bordered labelStyle={{ width: '150px' }}>
                        <Descriptions.Item label="게임명">
                            <Form.Item
                                name="globalId"
                                rules={formValidator.getRules('create_project_game_id')}
                                initialValue={projectValues?.globalId}
                            >
                                <Select
                                    disabled={isProjectMenuList || mode !== BUTTON_TYPE.CREATE}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    optionFilterProp="children"
                                    data-test_id="gameId"
                                    onChange={async (v) => {
                                        setGameId(v);
                                    }}
                                >
                                    {allGameList?.map((it) => (
                                        <Select.Option
                                            key={it.id}
                                            value={it.gameId}
                                            data-test_id={`gameId_${it.gameId}`}
                                        >
                                            {it.gameName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Descriptions.Item>
                        {!isProjectMenuList ? (
                            <>
                                <Descriptions.Item label="환경">
                                    <Form.Item
                                        name="environment"
                                        rules={formValidator.getRules('create_project_env')}
                                        initialValue={projectValues?.environment}
                                    >
                                        <Radio.Group disabled={isProjectMenuList || mode !== BUTTON_TYPE.CREATE}>
                                            {isProd ? (
                                                <Radio value={EProjectEnvironment.PRODUCTION}>
                                                    {EProjectEnvironment.PRODUCTION.toLocaleUpperCase()}
                                                </Radio>
                                            ) : (
                                                <>
                                                    <Radio value={EProjectEnvironment.QA}>
                                                        {EProjectEnvironment.QA.toLocaleUpperCase()}
                                                    </Radio>
                                                    <Radio value={EProjectEnvironment.DEVELOPMENT}>
                                                        {EProjectEnvironment.DEVELOPMENT.toLocaleUpperCase()}
                                                    </Radio>
                                                </>
                                            )}
                                        </Radio.Group>
                                    </Form.Item>
                                </Descriptions.Item>
                                <Descriptions.Item label="시트 ID 사용">
                                    <Form.Item
                                        name="hasSheetId"
                                        rules={formValidator.getRules('sheet_id')}
                                        initialValue={!!projectDetail?.driveUrl}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Radio.Group
                                                value={hasSheetId}
                                                onChange={(e) => {
                                                    setHasSheetId(e.target.value);
                                                }}
                                                disabled={isProjectMenuList}
                                            >
                                                <Radio value={true}>시트 ID 사용</Radio>
                                                <Radio value={false}>시트 ID 사용 X (A+B)</Radio>
                                            </Radio.Group>
                                        </div>
                                    </Form.Item>
                                </Descriptions.Item>

                                {hasSheetId !== undefined &&
                                    (hasSheetId ? (
                                        <>
                                            <Descriptions.Item label="시트 ID">
                                                <div css={sheetWrapper}>
                                                    <GameSheetModal
                                                        onSubmit={(sheetId) => {
                                                            setSheetInfo({ ...sheetInfo, driveUrl: sheetId });
                                                            setGameSheetModalVisible(false);
                                                        }}
                                                        gameId={gameId}
                                                        projectDetail={projectDetail}
                                                        visible={gameSheetModalVisible}
                                                        onCancel={() => setGameSheetModalVisible(false)}
                                                    />
                                                    {sheetInfo.driveUrl && <Tag>{sheetInfo.driveUrl}</Tag>}

                                                    <div css={buttonWrapper}>
                                                        <Button
                                                            onClick={() => {
                                                                if (gameId === -1 || gameId === null) {
                                                                    Modal.info({
                                                                        title: '확인',
                                                                        content: '게임을 선택해주세요.',
                                                                    });
                                                                    return;
                                                                }
                                                                setGameSheetModalVisible(true);
                                                            }}
                                                        >
                                                            {sheetInfo.driveUrl ? '변경 하기' : '등록 하기'}
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                window.open(
                                                                    `https://docs.google.com/spreadsheets/d/${sheetInfo.driveUrl}`,
                                                                );
                                                            }}
                                                            disabled={!sheetInfo.driveUrl}
                                                        >
                                                            바로가기
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Descriptions.Item>
                                        </>
                                    ) : (
                                        <>
                                            <Descriptions.Item
                                                label={
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            verticalAlign: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        언어 추가
                                                        {sheetLanguages.length !== 0 && (
                                                            <Tooltip
                                                                color="#000000d9"
                                                                overlayStyle={{
                                                                    maxWidth: '520px',
                                                                }}
                                                                overlayInnerStyle={{ padding: 10 }}
                                                                title={
                                                                    <>
                                                                        <div>게임시트에서 사용중인 언어 코드</div>
                                                                        {sheetLanguages.map((it) => (
                                                                            <Tag key={it}>{it}</Tag>
                                                                        ))}
                                                                    </>
                                                                }
                                                            >
                                                                <InfoCircleOutlined style={{ marginLeft: '10px' }} />
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                }
                                            >
                                                {isProjectMenuList ? (
                                                    <>
                                                        {(projectValues?.languages ?? []).map((lang) => (
                                                            <Tag key={lang}>{lang}</Tag>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    (option?.label ?? '')
                                                                        .toLowerCase()
                                                                        .includes(input.toLowerCase()) ||
                                                                    (option?.value ?? '')
                                                                        .toLowerCase()
                                                                        .includes(input.toLowerCase())
                                                                );
                                                            }}
                                                            options={Object.keys(LanguageCodes).map((it) => {
                                                                const lang =
                                                                    LanguageCodes[it as keyof typeof LanguageCodes];
                                                                return {
                                                                    label: `${lang} (${it})`,
                                                                    value: lang,
                                                                    key: it,
                                                                };
                                                            })}
                                                            value={languages}
                                                            onChange={(_, option) => {
                                                                console.log(option);
                                                                setLanguages((option as any[]).map((it) => it.value));
                                                            }}
                                                        ></Select>
                                                    </>
                                                )}
                                            </Descriptions.Item>
                                        </>
                                    ))}
                                <Descriptions.Item label="서버 URL">
                                    <Form.Item
                                        name="serverUrl"
                                        rules={[
                                            {
                                                validator: (_, value: string) => {
                                                    if (value.replace(/ /g, '')) {
                                                        if (!value.match(/^https?/)) {
                                                            return Promise.reject(
                                                                'http(s) 프로토콜만 사용할 수 있습니다.',
                                                            );
                                                        }
                                                    }

                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                        initialValue={sheetInfo.serverUrl ?? ''}
                                    >
                                        <Input
                                            placeholder="서버주소가 https로 시작하는지 확인해주세요."
                                            disabled={isProjectMenuList}
                                            onChange={(e) => {
                                                setSheetInfo((prev) => ({
                                                    ...prev,
                                                    serverUrl: e.target.value,
                                                }));
                                            }}
                                        />
                                    </Form.Item>
                                </Descriptions.Item>

                                {projectValues && (
                                    <Descriptions.Item label="담당자">
                                        <Form.Item name="managers">
                                            {!isProjectMenuList && (
                                                <>
                                                    <Search
                                                        placeholder="아이디나 별명을 입력해주세요"
                                                        onSearch={onSearch}
                                                        onFocusCapture={() => setDropDownVisible(false)}
                                                        enterKeyHint="search"
                                                        enterButton="검색"
                                                        ref={searchRef as never}
                                                    />
                                                    {dropDownVisible && (
                                                        <AccountDropDown
                                                            accounts={searchResultAccounts}
                                                            onClick={onAccountItemClick}
                                                        />
                                                    )}
                                                </>
                                            )}

                                            <div className={cx('userBadgeWrapper')}>
                                                {projectAccounts?.map((account) => (
                                                    <Tag
                                                        color={isProjectMenuList ? '' : 'blue'}
                                                        key={account.id}
                                                        closable={!isProjectMenuList}
                                                        onClose={() => {
                                                            removeAccounts(account);
                                                        }}
                                                    >
                                                        {account.name}
                                                    </Tag>
                                                ))}
                                            </div>
                                        </Form.Item>
                                    </Descriptions.Item>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                        {projectDetail && isProjectMenuList && (
                            <Descriptions.Item label="프로젝트 메뉴">
                                <ProjectMenuEdit project={projectDetail} />
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                    <div css={footer}>
                        <Button onClick={onCancel}>취소</Button>
                        {!isProjectMenuList ? (
                            <Button htmlType="submit" type="primary">
                                저장
                            </Button>
                        ) : (
                            <></>
                        )}
                    </div>
                </EnterIgnoreForm>
            </section>
        </ModalWrapper>
    );
};

export default ProjectModal;

const footer = css`
    margin-top: 16px;
    text-align: right;
    > button {
        margin-left: 10px;
    }
`;

const buttonWrapper = css`
    display: flex;
    justify-content: center;
`;

const sheetWrapper = css`
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
`;
